<template>
  <div>
    <div class="condition">
      <div class="condition_item1">
        <el-input
          class="condition_item1_input"
          placeholder="请输入设备编码或关键字"
          v-model="deviceCode"
        ></el-input>
        <span id="client">
          <el-select clearable v-model="clienteleName" placeholder="请选择客户">
            <el-option
              v-for="item in clientele"
              :key="item.customerId"
              :label="item.customerName"
              :value="item.customerId"
            >
            </el-option>
          </el-select>
        </span>
        <span id="organization">
          <!-- <el-select
            clearable
            v-model="organizationName"
            placeholder="请选择组织"
          >
            <el-option
              v-for="item in organization"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select> -->
          <select-tree
            style="width:168px"
            ref="selectTree"
            :props="props"
            placeholder="请选择组织"
            :options="organization"
            :clearable="true"
            :accordion="true"
            @getValue="getValue($event)"
          />
        </span>
        <span id="disposeState">
          <el-select clearable v-model="State" placeholder="设备类型">
            <el-option
              v-for="item in disposeState"
              :key="item.valeu"
              :label="item.type"
              :value="item.valeu"
            >
            </el-option>
          </el-select>
        </span>
        <!--                    <el-input v-model="minNum" class="numberScope" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="数量范围"></el-input>-->
        <!--                <span>-</span>-->
        <!--                     <el-input v-model="maxNum " style="margin: 0" class="numberScope" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="数量范围"></el-input>-->
        <el-button class="buttons button_default" @click="querys(true)"
          >查询</el-button
        >
        <a :href="exportUrl"
          ><el-button class="buttons import" icon="el-icon-upload2"
            >导出</el-button
          ></a
        >
      </div>
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="客户名称">
          <template slot-scope="scope">{{ scope.row.customerName }}</template>
        </el-table-column>
        <el-table-column prop="deptName" label="组织"> </el-table-column>
        <el-table-column
          prop="deviceType"
          label="设备类型"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="deviceFeature"
          label="设备型号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="assetCount"
          label="资产数量"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import {
  assetQuery,
  gitName,
  organization,
  getDeviceTypes,
  queryDeptAll
} from "@/api/AssetManagement";
export default {
  name: "index",
  data() {
    return {
      minNum: "",
      maxNum: "",
      clienteleName: "",
      clientele: [],
      faultState: "",
      disposeState: [],
      organization: [],
      State: "",
      organizationName: "",
      pages: 1,
      exportUrl:
        window.baseUrl +
        "/clodchainAM/web/export_customer_asset_statistics_info?" +
        "token=" +
        localStorage.getItem("token") +
        "&userId=" +
        localStorage.getItem("userId"),
      fault: "",
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      deviceCode: "",
      number: 0,
      tableData: [],
      multipleSelection: [],
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      }
    };
  },
  created() {
    this.querys();
    this.getorganization();
    this.getClient();
    this.getgetDeviceType();
  },
  methods: {
    getValue(value) {
      this.organizationName = value;
    },
    //获取设备类型
    getgetDeviceType() {
      getDeviceTypes({}).then(res => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i] != null)
            this.disposeState.push({
              valeu: data[i],
              type: data[i]
            });
        }
      });
    },
    //获取组织
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.organization.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.organization.push(value);
          }
        });
      });
    },
    //获取客户
    getClient() {
      gitName({}).then(res => {
        this.clientele = res.data.data;
      });
    },
    //查询
    querys(type) {
      if (type) {
        this.currentPage = 1;
        this.pages = 1;
      }
      let data = {
        keyword: this.deviceCode,
        // keyword: this.clienteleName,
        customerId: this.clienteleName,
        deptId: this.organizationName,
        deviceType: this.State,
        deviceFeature: this.deviceCode,
        minNum: this.minNum,
        maxNum: this.maxNum,
        limit: 12,
        curPage: this.pages
      };
      assetQuery(data).then(res => {
        this.tableData = res.data.data.list;
        this.recordCount = res.data.data.total;
      });
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.querys(false);
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.querys(false);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.querys(false);
      console.log(`当前页: ${val}`);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style scoped>
.import {
  color: #6e7790;
  background: #f1f1f4;
  border-color: #c5c9d3;
}
.import:hover {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.import:focus {
  background: #6e7790;
  border-color: #6e7790;
  color: #fff;
}
.button_default {
  color: #0cc;
  border-color: #99ebeb;
}
.button_default:hover {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
.button_default:focus {
  color: #fff;
  border-color: #b3f0f0;
  background-color: #0cc;
}
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}

.condition {
  margin-top: 20px;
}
.condition_item1 {
  /*width: 840px;*/
  margin-bottom: 20px;
}
.condition_item1_input {
  width: 246px;
}
/deep/ #organization .el-select {
  display: inline-block;
  position: relative;
  width: 168px;
  margin-left: 14px;
}
/deep/ #client .el-select {
  display: inline-block;
  position: relative;
  width: 168px;
  margin-left: 14px;
}
/deep/ #disposeState .el-select {
  display: inline-block;
  position: relative;
  width: 120px;
  margin-left: 14px;
}
/deep/ #faultState .el-select {
  display: inline-block;
  position: relative;
  width: 110px;
  margin-left: 14px;
}
/deep/ #timeSpan .el-select {
  display: inline-block;
  position: relative;
  width: 96px;
  margin-left: 14px;
}
.numberScope {
  width: 90px;
  margin-left: 14px;
}
.buttons {
  width: 96px;
  margin-left: 14px;
}
.paging {
  margin-top: 20px;
}
</style>
